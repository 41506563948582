import React from 'react';
import './CourseCardStyle.css'; // Import your CSS file
import { Link } from 'react-router-dom';

const CourseCard = ({course}) => {

    return (
        <div className="course-card">
            <img src={course.image} alt={course.name} />
            <h2>{course.name}</h2>
            {course.isDemoAvailable != false
                ? <Link to="/demoform">
                    <button className="cta-button">Programează o ședință demonstativă gratuită</button>
                </Link>
                : 
                <strong>Coming soon...</strong>
            }
            {course.price != null
                ? <p><strong>RON {course.price} / 4 ședințe</strong></p> : ""
            }
            <p>{course.description}</p>
            
        </div>
    );
}

export default CourseCard;
