import React from 'react';
import './AboutPageStyle.css';
import { Link } from 'react-router-dom';
const AboutDescription = () => {

    const title = "Despre noi";
    const p1 = "Bine ați venit la MediaInfo Online Programming School! La MediaInfo, suntem dedicați dezvoltării viitorului prin intermediul educației online în domeniul programării și dorim să oferim oportunități de învățare pentru copii și adolescenți, transformându-i în viitorii programatori de succes.";
    const p2 = "Ce ne face speciali:";
    const strong1 = ["Sesiuni Online Interactive: ",
        "La MediaInfo, știm cât de importantă este implicarea și interacțiunea pentru o învățare eficientă. De aceea, toate sesiunile noastre de învățare sunt conduse online, în timp real, pe platforma Zoom. Fiecare sesiune durează 90 de minute, oferind timp suficient pentru a explora, aprofunda și aplica cunoștințele într-un mediu de învățare prietenos și interactiv."
    ];
    const strong2 = ["Grupuri Mici: ",
        "Pentru a asigura o experiență de învățare personalizată și atentă, ținem grupurile de studenți la un număr de 5 cursanți. Acest lucru ne permite să oferim fiecărui student suport individualizat, răspuns la întrebări și feedback detaliat."
    ];
    const p3 = "Credem în puterea educației pentru a transforma vieți și a construi un viitor mai luminos. Suntem aici pentru a inspira și a ghida viitoarele generații de programatori. Alătură-te nouă în această călătorie a cunoașterii și descoperă lumea fascinantă a programării.";
    const buttonText = "Programează o ședință gratuită la cursul dorit";

    const strong3 = ["Vom oferi materiale scrise cursanților pentru a le permite recapitularea și aprofundarea noțiunilor studiate la cursurile noastre."];

    const strong4 = ["De ce să ai încredere în noi?",
    "La MEDIAINFO abordăm politica de returnare a ultimei plăți în cazul în care plătitorul se declară nemulțumit de serviciile noastre, la cel mult 30 de zile după efectuarea plății."
    ];

    return (
        <div className="about">
            <h2>{title}</h2>
            <p>
                {p1}
            </p>

            <p>
                {p2}
            </p>
            <ul className='aboutUL'>
                <li className='aboutLI'>
                    <strong>{strong1[0]}</strong> {strong1[1]}
                </li>
                <li className='aboutLI'>
                    <strong>{strong2[0]}</strong> {strong2[1]}
                </li>
                <li className='aboutLI'>
                    <strong>{strong3[0]}</strong>
                </li>
                <li className='aboutLI'>
                    <strong>{strong4[0]}</strong> {strong4[1]}
                </li>
            </ul>

            <p>
               {p3}
            </p>
            <Link to="/demoform">
                <button className="cta-button">{buttonText}</button>
            </Link>
        </div>
    );
};

export default AboutDescription;
