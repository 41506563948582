import React, { useEffect, useState } from 'react';
import './Navbar.css';

const NavbarComponent = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [currentPath, setCurrentPath] = useState(window.location.pathname)

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname)
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange)
    };
  }, []);


  return (
    <>
      <nav>
        {/* <div className="logo">
            <a href={process.env.PUBLIC_URL + "/"}>
              <img
                src={process.env.PUBLIC_URL + "/Logo_pt_fundal_inchis.png"}
                alt="Logo"
              />
            </a>
          </div> */}

        <a href={process.env.PUBLIC_URL + "/"}>
          <img
            id="logoimg"
            src={process.env.PUBLIC_URL + "/Logo_pt_fundal_inchis_mic.png"}
            alt="Logo"
            style={{ width: "120px", height: "auto" }}
          />
        </a>

        <div>
          <ul
            id="navbar"
            className={menuOpen ? "#navbar active" : "navbar"}
          >
            <li>
              <a className={currentPath=='/'? 'active':''} href="/">
                Acasă
              </a>
            </li>
            <li>
              <a className={currentPath=='/Cursuri'? 'active':''} href="/Cursuri">Cursuri</a>
            </li>
            <li>
              <a className={currentPath=='/DespreNoi'? 'active':''} href="/DespreNoi">Despre noi</a>
            </li>
          </ul>
        </div>

        <div id="mobile" onClick={toggleMenu}>
          <i
            className={menuOpen ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
      </nav>
    </>

  );
};

export default NavbarComponent;
