import React from 'react';
import './CoursesPageStyle.css';
import CoursesData from './CoursesData';
import CourseCard from './CourseCard/CourseCard';

function CoursesPage() {
    const courses = CoursesData
    return (

            <div className="courses-container">
                {courses.map((course, index) => (
                    <CourseCard key={index} course={course}/>
                ))}
            </div>

    );
}

export default CoursesPage;
