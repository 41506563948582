import './InformationCompStyle.css'
function InformationComp() {
    return(
        <div className='information-container'>
            <h1 className='yellow'>Informare!</h1>
            <h3>Înscrierile la cursuri se realizează momentan telefonic sau prin email!</h3>
            <h3 className='yellow'>+40 774 427 347 | courses@mediainfo.dev</h3>
        </div>
    )
}

export default InformationComp;