import React, { useState } from 'react';
import availableAges from './AvailableAges';
import './DemoFormStyle.css';
import demoCourses from '../../CoursesInfo/DemoCourses';

function DemoForm() {

  const [adultFirstName, setAdultFirstName] = useState('');
  const [adultLastName, setAdultLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [age, setAge] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [isBookingSubmitted, setIsBookingSubmitted] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');

  // Functions to check if fields are valid
  const isFirstNameValid = () => firstName.length > 0;
  const isLastNameValid = () => lastName.length > 0;
  const isAdultFirstNameValid = () => adultFirstName.length > 0;
  const isAdultLastNameValid = () => adultLastName.length > 0;
  const isEmailValid = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const isPhoneNumberValid = () => {
    const phoneNumberValue = phoneNumber.trim(); // Remove leading/trailing white spaces

    if (phoneNumberValue.startsWith("07")) {
      // Phone number starts with "07" and should be 10 characters long
      return phoneNumberValue.length === 10;
    } else if (phoneNumberValue.startsWith("+407")) {
      // Phone number starts with "+407" and should be 12 characters long
      return phoneNumberValue.length === 12;
    } else {
      // Invalid format
      return false;
    }
  };
  const isAgeValid = () => age.length > 0;
  const isSelectedCourseValid = () => selectedCourse.length > 0;
  const isSelectedDateValid = () => selectedDate.length > 0;

  const areAllFieldsCompleted = () => {
    return (
      adultFirstName !== '' &&
      adultLastName !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      age !== '' &&
      selectedDate !== '' &&
      selectedCourse !== ''
    );
  };

  const getCourseDays = (courseValue) => {
    const course = demoCourses.find((course) => course.value === courseValue);
    return course.days;
  };

  const handleBookingSubmit = async () => {
    setLoading(true);
    // Check if all fields are completed and valid
    if (areAllFieldsCompleted() && isPhoneNumberValid()
      && isAgeValid() && isEmailValid()
      && isAdultFirstNameValid() & isAdultLastNameValid()
      && isFirstNameValid() & isLastNameValid()
      && isSelectedCourseValid() && isSelectedDateValid()) {
      // Implement booking submission logic here, e.g., using an API call.
      // You can use libraries like Axios to make HTTP requests.

      const formData = [
        [
          firstName,
          lastName,
          email,
          phoneNumber,
          age,
          selectedCourse,
          selectedDate,
          adultFirstName,
          adultLastName
        ]
      ];

      try {
        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: "post",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify(formData)
        };

        await fetch(process.env.REACT_APP_APIFORM, requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(result)
            setIsBookingSubmitted(true);
          })
          .catch(error => console.log('error', error));

        // After successful submission, update the state:
      } catch (err) {
        console.log(err)
      }
    } else {
      // Display an error message or handle invalid form submission
      alert('Please complete all fields with valid information.');
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="App">
        <main>
          {isBookingSubmitted ? (
            <div>
              <h2 className='black'>Tocmai ați rezervat o ședință demo!</h2>
              <p>Vă vom contacta în curând!</p>
            </div>
          ) : (
            <div>
              <h2 className='black'>Rezervă o ședință gratuită</h2>
              <form>
              <label htmlFor="name">Nume reprezentant legal</label>
                <input
                  type="text"
                  id="name"
                  value={adultLastName}
                  onChange={(e) => setAdultLastName(e.target.value)}
                />
                <label htmlFor="name">Prenume reprezentant legal</label>
                <input
                  type="text"
                  id="name"
                  value={adultFirstName}
                  onChange={(e) => setAdultFirstName(e.target.value)}
                />
                <label htmlFor="name">Nume elev</label>
                <input
                  type="text"
                  id="name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <label htmlFor="name">Prenume elev</label>
                <input
                  type="text"
                  id="name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label htmlFor="email">Email contact</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="phoneNumber">Număr telefon</label>
                <div className="phone-input">
                  <input
                    type="text"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <label htmlFor="age">Vârstă elev</label>
                <select
                  id="age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                >
                  <option value="">Neselectat</option>
                  {availableAges.map((age, index) => (
                    <option key={index} value={age}>
                      {age} ani
                    </option>
                  ))}
                  <option value="21">17+ ani</option>
                  {/* Add more age options as needed */}
                </select>
                <label htmlFor="course">Selectează cursul pentru care dorești o ședință gratuită:</label>
                <select
                  id="course"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <option value="">Neselectat</option>
                  {/* aici pun cursurile disponibile de demo */}
                  {demoCourses.map((course, index) => (
                    <option key={index} value={course.value}>
                      {course.value}
                    </option>
                  ))}
                </select>
                <label htmlFor="date">Selectează ziua:</label>
                <select
                  id="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                >
                  <option value="">Neselectat</option>
                  {selectedCourse &&
                    getCourseDays(selectedCourse).map((day, index) => (
                      <option key={index} value={day}>
                        {day}
                      </option>
                    ))
                  }
                </select>
                {selectedCourse!='' && getCourseDays(selectedCourse).length > 0 ?
                  <button
                    type="button"
                    onClick={handleBookingSubmit}
                    disabled={!areAllFieldsCompleted() || loading} // Disable the button if not all fields are completed
                  >
                    {loading ? "Se încarcă..." : "Rezervă"}
                  </button>
                  : <span>Locurile pentru această ședință sunt ocupate.</span>
                }
              </form>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DemoForm;