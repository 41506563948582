const demoCourses = [
    //C++ DE LA 0
    {value: "C++ de la 0", days:[]},
    //PYTHON DE LA 0
    {value: "Python de la 0", days:[]},
    //ROBLOX STUDIO
    {value: "Roblox Studio", days:[]},
    //C++ pentru BAC
    {value: "C++ pentru BAC", days:[]}
]

export default demoCourses;