// Course.js
class Course {
    constructor(id, name, description, image, price, isDemoAvailable) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.image = image;
      this.price = price;
      this.isDemoAvailable = isDemoAvailable;
    }
  }
  
  export default Course;
  