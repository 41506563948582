import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DemoForm from './View/DemoFormPage/DemoForm';
import UnderConstructionPage from './View/OnBuildingPage/OnBuildingPage';
import CoursesPage from './View/CoursesPage/CoursesPage';
import AboutPage from './View/AboutPage/AboutPage';

function App() {
    useEffect(() => {
        document.title = 'MediaInfo';
      }, []);
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<UnderConstructionPage />} />
              <Route path="/DemoForm" element={<DemoForm/>} />
              <Route path="/ContactForm" element={<UnderConstructionPage/>} />
              <Route path="/Cursuri" element={<CoursesPage/>} />
              <Route path="/DespreNoi" element={<AboutPage/>} />
          </Routes>
      </BrowserRouter>
  )
}

export default App;

