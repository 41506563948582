import React from 'react';
import './OnBuildingPageStyle.css';
import { Link } from 'react-router-dom';

const UnderConstructionPage = () => {
  return (
    <div className="under-construction-container">
    <div className="video-container">
      <video autoPlay loop muted>
        <source src={process.env.PUBLIC_URL + '/video/homepageVideo.mp4'} type="video/mp4" />
      </video>
    </div>
    <div className="content-overlay">
      <h1 className="main-text">Cursuri ONLINE de programare pentru copii și adolescenți.</h1>

      <a className='btn-link' href="/Cursuri">
        <button className='btn'><strong>Programează o ședință gratuită</strong></button>
      </a>
    </div>
  </div>
  );
};

export default UnderConstructionPage;
