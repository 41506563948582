import React from 'react';
import './FooterStyle.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section">
          <h2>Despre Noi</h2>
          <p>
            MediaInfo Programming School oferă cursuri de programare online pentru copiii și adolescenții din România.
          </p>
        </div>
        <div className="footer-section">
          <h2>Contact</h2>
          <p>
            Telefon: +40 774 427 347
          </p>
          <p>
            Email: courses@mediainfo.dev
          </p>
        </div>
        <div className="footer-section">
          <h2>Linkuri Utile</h2>
          <ul>
            <li><a href="/Cursuri">Cursuri</a></li>
            <li><a href="/DespreNoi">Despre Noi</a></li>
            <li><a href="/">Acasă</a></li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; {new Date().getFullYear()} MediaInfo Programming School. Toate drepturile rezervate.</p>
      </div>
    </footer>
  );
}



export default Footer;
