import Course from "../../Models/Course";
const CoursesData = [
    new Course(1,
        'C++ de la 0',
        'Cursul are un total de 16 ședințe. Grupe de maxim 5 elevi. Realizăm aplicații cu interfață grafică și învățăm noțiuni teoretice de programare orientată pe obiecte.',
        process.env.PUBLIC_URL +'/images/coursesLogos/C++_logo_1_DEAL.png',
        200, 
        true),
    new Course(2,
        'Python de la 0',
        'Cursul are un total de 20 ședințe. Grupe de maxim 5 elevi. Realizăm jocuri 2D și aplicații cu interfață grafică și învățăm să utilizăm diferite librării.',
        process.env.PUBLIC_URL +'/images/coursesLogos/Python_logo_1_DEAL.png',
        200, 
        true),
    new Course(3,
        'Lua în Roblox Studio',
        'Cursul are un total de 16 ședințe. Grupe de maxim 5 elevi. Programăm jocuri 3D în ecosistemul Roblox și învățăm să controlăm relația dintre server și clienți.',
        process.env.PUBLIC_URL +'/images/coursesLogos/RS_logo_1.png',
        400, 
        true),
    new Course(4,
        'Scratch pentru începători',
        'Cursul are un total de 16 ședințe. Grupe de maxim 5 elevi. Recomandat începătorilor. Punem bazele gândirii algoritmice prin realizarea de jocuri și mini-aplicații 2D în Scratch.',
        process.env.PUBLIC_URL +'/images/coursesLogos/Scratch_logo_1.png',
        400,
        true),
    new Course(5,
        'C++ pentru BAC',
        'Recomandat elevilor de clasa a 11-a și a 12-a. Cursul asigură pregătirea elevilor de liceu pentru susținerea examenului de bacalaureat la informatică.',
        process.env.PUBLIC_URL +'/images/coursesLogos/C++_logo_1.png',
        400, 
        true),
    new Course(6,
        'Mate pentru BAC',
        'Recomandat elevilor de clasa a 11-a și a 12-a. Cursul asigură pregătirea elevilor de liceu pentru susținerea examenului de bacalaureat la matematică.',
        'https://images.pexels.com/photos/2097/desk-office-pen-ruler.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        null, 
        false)

    // Add more courses here
];

export default CoursesData;